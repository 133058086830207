.project-list {
    max-width: 500px;
    margin: 40px auto;
}

.general-container {
    min-height: 100vh;
}

.create-new-project {
    margin: 30px auto;
    display: flex;
    align-self: auto;
}
