.spider-data {
    width: 800px;
    margin: 30px auto;
}

.create-new-job {
    margin: 30px auto;
    display: flex;
    align-self: auto;
}
