.logo-header {
    float: left;
    width: 120px;
    height: 31px;
    font-size: 24px;
}

.header-item,
.header-item:hover {
    margin: auto 15px;
    float: right;
    color: #ffffff;
    cursor: pointer;
}
