.cronjob-list {
    max-width: 700px;
    margin: 40px auto;
}

.table {
    margin: 40px auto;
}

.general-container {
    min-height: 100vh;
}
