.stop-job {
    margin: 0px auto;
    display: flex;
    align-self: auto;
}

.go-to-job-data {
    margin: 10px auto;
    display: flex;
    align-self: auto;
}