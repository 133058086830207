body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white-background {
    background-color: #ffffff;
}

.text-center {
    text-align: center;
}

.content-padding {
    padding: 30px;
}

.general-container {
    min-height: 100vh;
}

.pagination {
    margin: 20px auto;
    display: flex;
    justify-content: center;
}
