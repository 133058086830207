.general-container {
    min-height: 100vh;
}
.create-new-job {
    margin: 30px auto;
    display: flex;
    align-self: auto;
}

.data-list {
    max-width: 500px;
    margin: 40px auto;
}

.list-item {
    max-width: 500px;
}
