.register-content {
    width: 500px;
    padding: 20px;
    margin: 50px auto;
}

.register-inputs {
    margin: 40px auto;
}

.register-button {
    width: 200px;
    font-weight: 500;
    margin: 0px calc(50% - 100px);
}
